import _ from 'lodash'
import ProductsApi from '../../api/products'

const esb = require('elastic-builder')

export function updateSearchProductsParams(params) {
  return async dispatch => {
    dispatch({
      type: 'RPM/SEARCH_PRODUCTS/PARAMS_UPDATED',
      params,
    })
  }
}

export function updateSearchProductsPaginationParams(page, perPage = 10) {
  return async dispatch => {
    dispatch({
      type: 'RPM/SEARCH_PRODUCTS/PAGINATION_PARAMS_UPDATED',
      pagination: {
        page,
        perPage,
      },
    })
  }
}

export function searchProducts() {
  return async (dispatch, getState) => {
    dispatch({
      type: 'RPM/SEARCH_PRODUCTS/SEARCH_INIT',
    })

    const state = getState()
    const { params, pagination } = state.renaultProjectMode.searchProducts
    const {
      query,
      orderBy,
      includeConfigurations,
      includeBenchmarks,
      templateId,
      projectId,
      excludedProductIds,
      includedProductIds,
    } = params

    const status = state.status.list

    const isBenchmark = `is_benchmark`
    const benchmarkStatusSlugs = status.filter(s => s[isBenchmark]).map(s => s.slug)

    const orderByOptions = {
      az_asc: [
        {
          'props.brand.value': 'asc',
        },
        {
          'props.model.value': 'asc',
        },
      ],
      az_desc: [
        {
          'props.brand.value': 'desc',
        },
        {
          'props.model.value': 'desc',
        },
      ],
      created_at_desc: [
        {
          created_at: 'desc',
        },
      ],
      created_at_asc: [
        {
          created_at: 'asc',
        },
      ],
    }

    const { page, perPage } = pagination

    const requestBody = ProductsApi.initSearchQuery()

    const isConfiguration = 'is_configuration'

    // prepare product search query by including product benchmark and exclude configuration
    let searchProductQuery = esb
      .boolQuery()
      .must([esb.boolQuery().must(esb.termsQuery('status', benchmarkStatusSlugs))])
      .should([esb.boolQuery().mustNot(esb.existsQuery(isConfiguration)), esb.termQuery(isConfiguration, false)])

    if (projectId) {
      searchProductQuery = esb.boolQuery().must(
        esb
          .nestedQuery()
          .path(`projects_mapping`)
          .query(esb.boolQuery().must([esb.matchQuery(`projects_mapping.project.id`, projectId)]))
      )
    }

    // including product in project list (also configuration)
    if (includedProductIds) {
      searchProductQuery = searchProductQuery.should([esb.termsQuery('id', includedProductIds)])
    }

    // add query string
    if (query) {
      searchProductQuery = searchProductQuery.must(
        esb
          .boolQuery()
          .should([
            esb.wildcardQuery(`props.brand.value`, `*${query}*`),
            esb.wildcardQuery(`props.model.value`, `*${query}*`),
          ])
      )
    }

    // compose the query
    let requestSearch = requestBody.query(searchProductQuery)

    const filterQueries = []

    // prefilter with parametrer
    if (templateId) {
      filterQueries.push(esb.boolQuery().must(esb.termQuery('template.id', templateId)))
    }

    if (excludedProductIds) {
      filterQueries.push(esb.boolQuery().mustNot(esb.termsQuery('id', excludedProductIds)))
    }

    // filter with UI switcher parameter
    if (!includeBenchmarks) {
      // detect only configuration in current project list
      filterQueries.push(esb.boolQuery().filter(esb.termQuery(isConfiguration, true)))
    }

    if (!includeConfigurations) {
      filterQueries.push(esb.boolQuery().mustNot(esb.existsQuery(isConfiguration)))
    }

    // build Filter query with all filters
    if (filterQueries.length > 0) requestSearch = requestSearch.postFilter(esb.boolQuery().must(filterQueries))

    // response
    const response = await ProductsApi.search(requestSearch, orderByOptions[orderBy], page, perPage)

    const hits = _.map(response.hits.hits, hit => hit._source)
    const hitsKeydById = _.keyBy(hits, hit => hit.id)

    dispatch({
      type: 'RPM/SEARCH_PRODUCTS/SEARCHED',
      entities: hitsKeydById,
      pagination: {
        total: response.hits.total.value || 1,
      },
    })
  }
}

export function initSelectedProducts(selectedIds) {
  return async dispatch => {
    const product = selectedIds.length > 0 ? await ProductsApi.find(selectedIds[0]) : null

    dispatch({
      type: 'RPM/SEARCH_PRODUCTS/INIT',
      selectedIds,
      templateId: product ? product.template_id : null,
    })
  }
}

export function searchProductsSelectProduct(product) {
  return async dispatch => {
    dispatch({
      type: 'RPM/SEARCH_PRODUCTS/PRODUCT_SELECTED',
      productId: product.id,
      templateId: product.template?.id,
    })
  }
}

export function searchProductsDeselectProduct(product) {
  return async dispatch => {
    dispatch({
      type: 'RPM/SEARCH_PRODUCTS/PRODUCT_DESELECTED',
      productId: product.id,
    })
  }
}

export function searchProductsDeselectAllProducts() {
  return async dispatch => {
    dispatch({
      type: 'RPM/SEARCH_PRODUCTS/PRODUCT_DESELECTED_ALL',
    })
  }
}
