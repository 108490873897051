import _ from 'lodash'
import esb from 'elastic-builder'
import ProjectsApi from '../../api/projects'

export function updateSearchParams(params) {
  return async dispatch => {
    dispatch({
      type: 'RPM/SEARCH_PROJECTS/PARAMS_UPDATED',
      params,
    })
  }
}

export function updateSearchPaginationParams(page, perPage = 10) {
  return async dispatch => {
    dispatch({
      type: 'RPM/SEARCH_PROJECTS/PAGINATION_PARAMS_UPDATED',
      pagination: {
        page,
        perPage,
      },
    })
  }
}

export function searchProjects({ category }) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'RPM/SEARCH_PROJECTS/SEARCH_INIT',
    })

    const state = getState()
    const { params, pagination } = state.renaultProjectMode.search
    const { query, orderBy } = params
    const { page, perPage } = pagination

    const orderByOptions = {
      az_asc: [
        {
          name: 'asc',
        },
        {
          brand: 'asc',
        },
      ],
      az_desc: [
        {
          name: 'desc',
        },
        {
          brand: 'desc',
        },
      ],
      created_at_desc: [
        {
          created_at: 'desc',
        },
      ],
      created_at_asc: [
        {
          created_at: 'asc',
        },
      ],
    }

    const requestBody = ProjectsApi.initSearchQuery()

    const filterQueries = []
    if (category) filterQueries.push(esb.boolQuery().must(esb.termQuery('category', category)))

    if (filterQueries.length > 0) requestBody.postFilter(esb.boolQuery().must(filterQueries))

    const response = await ProjectsApi.search(query, orderByOptions[orderBy], page, perPage, requestBody)

    const hits = _.map(response.hits.hits, hit => hit._source)
    const hitsKeydById = _.keyBy(hits, hit => hit.id)

    dispatch({
      type: 'RPM/SEARCH_PROJECTS/SEARCHED',
      entities: hitsKeydById,
      pagination: {
        total: response.hits.total.value || 1,
      },
    })
  }
}

export function deleteSearchedProject(id) {
  return async dispatch => {
    await ProjectsApi.delete(id)

    dispatch({
      type: 'RPM/SEARCH_PROJECTS/DELETED_SEARCHED_PROJECT',
      id,
    })
  }
}
