import _ from 'lodash'
import nodeDefinitionTypeMap from '../../../components/scoring_tree/helper/nodeDefinitionTypeMap'

export const nodeHasModelChangeItem = (node, modelChangeItemId) => {
  return _.find(node.model_change, _mc => _mc.model_change_item_id === modelChangeItemId) !== undefined
}

export const getNodeModelChangeData = (node, modelChangeItemId) => {
  return _.find(node.model_change, _mc => _mc.model_change_item_id === modelChangeItemId)
}

export const initModelChangeDataToNode = (node, modelChangeItemId) => {
  node.model_change = node.model_change ? node.model_change : []

  if (!nodeHasModelChangeItem(node, modelChangeItemId)) {
    node.model_change.push({
      model_change_item_id: modelChangeItemId,
    })
  }
}

export const updateModelChangeData = (node, modelChangeItemId, data = {}) => {
  const modelChangeData = getNodeModelChangeData(node, modelChangeItemId)

  if (modelChangeData) {
    _.each(data, (value, key) => {
      modelChangeData[key] = value
    })
  }
}

export const hasChildrenCriterionModelChange = (template, nodeDefinition, modelChangeData, modelChangeItemId) => {
  if (!modelChangeItemId || !modelChangeData?.[modelChangeItemId] || !nodeDefinition?.id || !template) return false

  const childrenCriterionNodeDefinitions = _.filter(
    template.node_definitions,
    _nd =>
      _nd._left > nodeDefinition._left &&
      _nd._right < nodeDefinition._right &&
      _nd._right - _nd._left === 1 &&
      _nd.type === nodeDefinitionTypeMap.criterion
  )

  if (!childrenCriterionNodeDefinitions || childrenCriterionNodeDefinitions.length === 0) return false

  return childrenCriterionNodeDefinitions.some(nodeDef => modelChangeData[modelChangeItemId].includes(nodeDef.id))
}

export const hasChildrenCriterionAnyModelChange = (template, nodeDefinition, modelChangeData) => {
  if (!nodeDefinition?.id || !template) return false

  const childrenCriterionNodeDefinitions = _.filter(
    template.node_definitions,
    _nd =>
      _nd._left > nodeDefinition._left &&
      _nd._right < nodeDefinition._right &&
      _nd._right - _nd._left === 1 &&
      _nd.type === nodeDefinitionTypeMap.criterion
  )

  if (!childrenCriterionNodeDefinitions || childrenCriterionNodeDefinitions.length === 0 || !modelChangeData)
    return false

  return childrenCriterionNodeDefinitions?.some(nodeDef =>
    Object.values(modelChangeData).some(modelChangeItemCriterions => modelChangeItemCriterions?.includes(nodeDef.id))
  )
}

/**
 * Calc product score with model change tweaks. This is based on a legacy algorithm.
 */
export const calculateProductScoreWithModelChange = (
  product,
  template,
  modelChangeItemId,
  enabledNodeDefinitionIds = []
) => {
  const nodeDefinitions = _.keyBy(template.node_definitions, 'id')
  const productNodes = _.keyBy(product.nodes, 'node_definition_id')

  const criteria = []
  const demerits = []

  // Get Criteria and Demerits
  _.each(nodeDefinitions, nodeDefinition => {
    const node = productNodes[nodeDefinition.id]
    if (
      node &&
      nodeDefinition.type === nodeDefinitionTypeMap.criterion &&
      node.is_enabled === true &&
      enabledNodeDefinitionIds.includes(nodeDefinition.id)
    ) {
      if (nodeDefinition.bonus_demerit === false) {
        criteria.push(nodeDefinition)
      } else {
        demerits.push(nodeDefinition)
      }
    }
  })

  // Empty all scores but criteria
  _.each(productNodes, node => {
    const { type } = nodeDefinitions[node.node_definition_id]
    if (type !== nodeDefinitionTypeMap.criterion) {
      // Assign and not toggle because i don't want to remove the informations about model change scores, just update them
      initModelChangeDataToNode(node, modelChangeItemId)
      updateModelChangeData(node, modelChangeItemId, {
        score: 0,
        max_score: 0,
        normalized_score: 0,
      })
    }
  })

  // STEP 1
  // -----------------------------------------------------------------------------------------
  // For each Criterion multiply score*weight then for parents weight
  // on treeMap from criteria to perimeters
  _.each(criteria, criterion => {
    // get scoring node
    const node = productNodes[criterion.id]

    // Start calc on criterion
    let calc = node.score * nodeDefinitions[node.node_definition_id].weight
    let maxCalc = global.env.config.max_score * nodeDefinitions[node.node_definition_id].weight

    let parentNodeDefinitionId = criterion.parent_id

    while (parentNodeDefinitionId !== null) {
      // For each criterion's parent
      const parentNodeDefinition = nodeDefinitions[parentNodeDefinitionId]

      // Do calc
      calc *= parentNodeDefinition.weight
      maxCalc *= parentNodeDefinition.weight

      // Get this node father id
      parentNodeDefinitionId = parentNodeDefinition.parent_id
    }

    // Restart from Item
    parentNodeDefinitionId = criterion.parent_id

    // For each criterion's parent sum calc
    // Also here we loop 4 levels (item, subfamily, family, perimeter)
    while (parentNodeDefinitionId !== null) {
      const parentNode = productNodes[parentNodeDefinitionId]
      const modelChangeData = getNodeModelChangeData(parentNode, modelChangeItemId)

      // Sum calc on this node's score
      updateModelChangeData(parentNode, modelChangeItemId, {
        score: modelChangeData.score + calc,
        max_score: modelChangeData.max_score + maxCalc,
      })

      // Get this node father id
      parentNodeDefinitionId = nodeDefinitions[parentNodeDefinitionId].parent_id
    }
  })

  // STEP 2
  // -----------------------------------------------------------------------------------------
  // Calculate family demerit
  _.each(demerits, demerit => {
    const demeritNode = productNodes[demerit.id]

    const familyNode = _.find(
      nodeDefinitions,
      nodeDefinition =>
        nodeDefinitions.type === nodeDefinitionTypeMap.family &&
        nodeDefinition._left < demerit._left &&
        nodeDefinition._right > demerit._right
    )

    const familyModelChangeData = getNodeModelChangeData(familyNode, modelChangeItemId)
    const demeritModelChangeData = getNodeModelChangeData(demeritNode, modelChangeItemId)

    updateModelChangeData(familyNode, modelChangeItemId, {
      score:
        familyModelChangeData.score +
        (demeritModelChangeData.score * familyModelChangeData.score * global.env.config.demerit_behavior.value) / 100,
    })
  })

  // STEP 3
  // -----------------------------------------------------------------------------------------
  // Calculate Normalized scores
  const configMaxScore = JSON.parse(global.env.config.max_product_score)

  product.nodes = _.map(productNodes, node => {
    const nodeDefinition = nodeDefinitions[node.node_definition_id]

    if (nodeDefinition.type === nodeDefinitionTypeMap.criterion) {
      return node
    }

    const modelChangeData = getNodeModelChangeData(node, modelChangeItemId)

    let normalizedScore =
      modelChangeData.max_score > 0 ? (modelChangeData.score * configMaxScore) / modelChangeData.max_score : 0

    normalizedScore = normalizedScore > configMaxScore ? configMaxScore : normalizedScore

    updateModelChangeData(node, modelChangeItemId, {
      normalized_score: normalizedScore,
    })

    return node
  })

  return product
}
