import { createSelector } from 'reselect'
import _ from 'lodash'

const selectProjects = state => state.renaultProjectMode.projects.entities
const selectMapping = state => state.renaultProjectMode.projects.mapping
const selectTemplates = state => state.templates.list

export const selectProjectById = createSelector([selectProjects, (state, id) => id], (projects, id) => projects[id])

export const selectProjectMapping = createSelector(
  [selectMapping, (state, projectId) => projectId],
  (mapping, projectId) => mapping[projectId]
)

export const selectProjectMappingByConfigurationCategory = createSelector(
  [
    selectMapping,
    (state, projectId) => projectId,
    (state, projectId, configurationCategoryId) => configurationCategoryId,
  ],
  (mapping, projectId, configurationCategoryId = null) => {
    const filteredByProject = mapping[projectId]
    return _.filter(filteredByProject, item => item.configuration_category_id === configurationCategoryId)
  }
)

export const selectProjectMappingWithoutConfCategory = createSelector(
  [selectMapping, (state, projectId) => projectId],
  (mapping, projectId) => {
    const filteredByProject = mapping[projectId]
    return _.filter(filteredByProject, item => !item.configuration_category_id)
  }
)

export const selectProjectConfigurationCategoriesByType = createSelector(
  [selectProjects, (state, projectId) => projectId, (state, projectId, type) => type],
  (projects, projectId, type) => {
    const project = _.find(projects, item => item.id === projectId)
    return _.filter(project.configuration_categories, item => item.type === type)
  }
)

export const selectProjectConfigurationCategoryById = createSelector(
  [
    selectProjects,
    (state, projectId) => projectId,
    (state, projectId, configurationCategoryId) => configurationCategoryId,
  ],
  (projects, projectId, configurationCategoryId) => {
    const project = _.find(projects, item => item.id === projectId)
    return configurationCategoryId
      ? _.find(project.configuration_categories, item => item.id === configurationCategoryId)
      : null
  }
)

export const selectConfigurationCategoryProductTemplate = createSelector(
  [
    selectProjects,
    selectTemplates,
    (state, projectId) => projectId,
    (state, projectId, configurationCategoryId) => configurationCategoryId,
  ],
  (projects, templates, projectId, configurationCategoryId) => {
    const project = _.find(projects, item => item.id === projectId)
    const configurationCategory = configurationCategoryId
      ? _.find(project.configuration_categories, item => item.id === configurationCategoryId)
      : null

    if (!configurationCategory || !configurationCategory.comparison_board) {
      return null
    }

    const { products_data: productsData } = configurationCategory.comparison_board

    const firstProduct = _.first(_.values(productsData))

    if (!firstProduct) {
      return null
    }

    const { template_id: templateId } = firstProduct
    return _.find(templates, item => item.id === templateId)
  }
)

export const selectProjectConfCategoriesByProductId = createSelector(
  [selectProjects, selectMapping, (state, projectId) => projectId, (state, projectId, productId) => productId],
  (projects, mapping, projectId, productId) => {
    const project = _.find(projects, item => item.id === projectId)
    const mappingByProject = mapping[projectId]
    const mappingByProduct = _.filter(mappingByProject, item => item.product_id === productId)
    const configurationCategoryIds = _.map(mappingByProduct, item => item.configuration_category_id)
    return _.filter(project.configuration_categories, item => _.includes(configurationCategoryIds, item.id))
  }
)
