import _ from 'lodash'
import { httpDelete, httpGet, httpPost } from '../../../../va-corejs-v3/api'

const esb = require('elastic-builder')
const { Query } = require('cogent-js/src')

class ProductsApi {
  initSearchQuery = () => {
    const requestBody = esb.requestBodySearch()
    return requestBody
  }

  search = async (initialRequestBody = null, orderBy = null, page = 1, perPage = 10) => {
    const requestBody = initialRequestBody || esb.requestBodySearch()

    requestBody.from((page - 1) * perPage)
    requestBody.size(perPage)

    if (orderBy) {
      _.each(orderBy, value => {
        const orderByField = Object.keys(value)[0]
        const orderByDir = value[orderByField]
        requestBody.sort(esb.sort(orderByField, orderByDir))
      })
    }

    const response = await httpPost(`elastic/valueable/_search`, requestBody.toJSON())

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProductsApi.search')
    }

    return response.data
  }

  get = async (filters = {}, includes = []) => {
    const query = new Query({
      base_url: '',
    })

    let builder = query.for(`products`)

    // Filtering
    if (filters !== undefined && Object.keys(filters).length > 0) {
      _.each(filters, (value, key) => {
        if (Array.isArray(value)) {
          builder = builder.whereIn(key, value)
        } else {
          builder = builder.where(key, value)
        }
      })
    }

    // Includes
    if (includes.length > 0) {
      builder = builder.includes(...includes)
    }

    // Pagination
    builder.limit(100)

    const response = await httpGet(builder.url().substring(1)) // Need to remove the slash at the beginning of the string

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProductsApi.get')
    }

    return response.data.data // additional .data Because it is paginated but I don't care in this method
  }

  find = async id => {
    const response = await httpGet(`products/${id}`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProductsApi.find')
    }

    return response.data
  }

  allScoringTreeComments = async productId => {
    const response = await httpGet(`products/${productId}/scoring-tree/comments/all`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProductsApi.allScoringTreeComments')
    }

    return response.data
  }

  allScoringTreeMedia = async productId => {
    const response = await httpGet(`products/${productId}/scoring-tree/media/all`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProductsApi.allScoringTreeMedia')
    }

    return response.data
  }

  delete = async id => {
    const response = await httpDelete(`products/${id}`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProductApi.delete')
    }

    return response.data
  }
}

const instance = new ProductsApi()

export default instance
