import _ from 'lodash'
import { httpGet, httpPost, httpPatch, httpDelete } from '../../../../va-corejs-v3/api'

const esb = require('elastic-builder')
const { Query } = require('cogent-js/src')

class ProjectsApi {
  initSearchQuery = () => {
    const requestBody = esb.requestBodySearch()
    return requestBody
  }

  search = async (query, orderBy = null, page = 1, perPage = 10, initialRequestBody = null) => {
    const requestBody = initialRequestBody || esb.requestBodySearch()

    requestBody.from((page - 1) * perPage)
    requestBody.size(perPage)

    if (query) {
      requestBody.query(esb.simpleQueryStringQuery(query))
    }

    if (orderBy) {
      _.each(orderBy, value => {
        const orderByField = Object.keys(value)[0]
        const orderByDir = value[orderByField]
        requestBody.sort(esb.sort(orderByField, orderByDir))
      })
    }

    const response = await httpPost(`elastic/projects/_search`, requestBody.toJSON())

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProjectsApi.search')
    }

    return response.data
  }

  find = async (id, includes = []) => {
    const query = new Query({
      base_url: '',
    })

    let builder = query.for(`projects/${id}`)

    if (includes.length > 0) {
      builder = builder.includes(...includes)
    }

    const response = await httpGet(builder.url().substring(1)) // Need to remove the slash at the beginning of the string

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProjectsApi.find')
    }

    return response.data
  }

  create = async attributes => {
    const response = await httpPost(`projects`, attributes, false)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProjectsApi.create')
    }

    return response.data
  }

  update = async (id, attributes) => {
    const response = await httpPatch(`projects/${id}`, attributes)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProjectsApi.update')
    }

    return response.data
  }

  delete = async id => {
    const response = await httpDelete(`projects/${id}`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProjectsApi.delete')
    }

    return response.data
  }
}

const instance = new ProjectsApi()

export default instance
