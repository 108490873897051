import _ from 'lodash'
import ProductsApi from '../../api/products'

// Projects actions
export function findProduct(id) {
  return async dispatch => {
    const item = await ProductsApi.find(id)

    dispatch({
      type: 'RPM/PRODUCTS_ADDED',
      products: {
        [item.id]: { ...item },
      },
    })

    return item
  }
}

export function fetchProducts(filters = {}, includes = []) {
  return async dispatch => {
    const response = await ProductsApi.get(filters, includes)

    dispatch({
      type: 'RPM/PRODUCTS_ADDED',
      products: _.keyBy(response, 'id'),
    })

    return response
  }
}

export function fetchScoringTreeMedia(productId) {
  return async dispatch => {
    const response = await ProductsApi.allScoringTreeMedia(productId)

    dispatch({
      type: 'RPM/MEDIA_ADDED',
      productId,
      media: response,
    })
  }
}

export function deleteProduct(id) {
  return async dispatch => {
    await ProductsApi.delete(id)
    dispatch({
      type: 'RPM/PRODUCT_REMOVED',
      id,
    })
  }
}
