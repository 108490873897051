import _ from 'lodash'
import { createScoringRecord } from '../../../../../va-corejs-v3/actions/scoring'
import storage from '../../../../../va-corejs-v3/storage'
import storageMap from '../../../../../va-corejs-v3/storage/storageMap'

export function initRenaultProjectModeConfiguration() {
  return async (dispatch, getState) => {
    // get configuration status
    const state = getState()
    const status = state.status.list
    const configurationStatus = status.find(s => s.is_configuration)

    const newProductId = await createScoringRecord({
      is_configuration: true,
      status: configurationStatus ? configurationStatus.slug : null,
    })

    dispatch({
      type: 'RPM/CREATE_PRODUCT/INIT',
      newProductId,
    })

    dispatch({
      type: 'UPDATE_NEW_SCORING_PANEL_VISIBLE',
      newScoringId: newProductId,
      newScoringPanelVisible: false, // I don't need the deafault modal
    })
  }
}

export function resetRenaultProjectModeProductCreation() {
  return async dispatch => {
    dispatch({
      type: 'RPM/CREATE_PRODUCT/RESET',
    })

    dispatch({
      type: 'RESET_SCORING_PANEL_SETTINGS',
    })
  }
}

/**
 * This method doesn't actually send the product to the server. It just store it in redux.
 * Actual server side storing process is performed by the "sync" system.
 *
 * @param {string} newProductId
 * @returns
 */
export function createRenaultProjectModeProduct(newProductId) {
  return async dispatch => {
    const newProduct = await storage.get(storageMap.scoring, newProductId)

    newProduct.nodes = _.values(newProduct.nodes)

    const covers = await storage.getWhere(storageMap.media, {
      scoring_id: newProductId,
      collection: 'cover_pictures',
    })

    const cover = await covers.first()

    if (!_.has(newProduct, 'media')) {
      newProduct.media = []
    }

    newProduct.media.push(cover)

    dispatch({
      type: 'RPM/PRODUCTS_ADDED',
      products: {
        [newProductId]: { ...newProduct },
      },
    })
  }
}
