import _ from 'lodash'
import { httpGet } from '../../../../va-corejs-v3/api'

const { Query } = require('cogent-js/src')

class ModelChangeItemsApi {
  get = async (filters = {}, includes = []) => {
    const query = new Query({
      base_url: '',
    })

    let builder = query.for(`model-change-items`)

    // Filtering
    if (filters !== undefined && Object.keys(filters).length > 0) {
      _.each(filters, (value, key) => {
        if (Array.isArray(value)) {
          builder = builder.whereIn(key, value)
        } else {
          builder = builder.where(key, value)
        }
      })
    }

    // Includes
    if (includes.length > 0) {
      builder = builder.includes(...includes)
    }

    // Pagination
    builder.limit(100)

    const response = await httpGet(builder.url().substring(1)) // Need to remove the slash at the beginning of the string

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ModelChangeItemsApi.get')
    }

    return response.data.data // additional .data Because it is paginated but I don't care in this method
  }
}

const instance = new ModelChangeItemsApi()

export default instance
