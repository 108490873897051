import { httpGet, httpPost, httpDelete } from '../../../../va-corejs-v3/api'

class ProjectMappingApi {
  get = async projectId => {
    const response = await httpGet(`projects/${projectId}/mapping`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProjectMappingApi.find')
    }

    return response.data
  }

  create = async (projectId, attributes) => {
    const response = await httpPost(`projects/${projectId}/mapping`, attributes, false)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProjectMappingApi.create')
    }

    return response.data
  }

  delete = async (projectId, productId, configurationCategoryId = null) => {
    const queryString = new URLSearchParams()

    queryString.append('product_id', productId)

    if (configurationCategoryId) {
      queryString.append('configuration_category_id', configurationCategoryId)
    }

    const response = await httpDelete(`projects/${projectId}/mapping?${queryString.toString()}`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProjectMappingApi.delete')
    }

    return response.data
  }
}

const instance = new ProjectMappingApi()

export default instance
