import { httpGet, httpPost, httpPatch, httpDelete } from '../../../../va-corejs-v3/api'

class ConfigurationCategoriesApi {
  find = async id => {
    const response = await httpGet(`configuration-categories/${id}`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ConfigurationCategoriesApi.find')
    }

    return response.data
  }

  create = async attributes => {
    const response = await httpPost(`configuration-categories`, attributes, false)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ConfigurationCategoriesApi.create')
    }

    return response.data
  }

  update = async (id, attributes) => {
    const response = await httpPatch(`configuration-categories/${id}`, attributes)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ConfigurationCategoriesApi.update')
    }

    return response.data
  }

  delete = async id => {
    const response = await httpDelete(`configuration-categories/${id}`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ConfigurationCategoriesApi.delete')
    }

    return response.data
  }
}

const instance = new ConfigurationCategoriesApi()

export default instance
